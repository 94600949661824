import { useState } from 'react';

// material-ui

// project-imports
import MovementPanel from './MovementPanel';
import IconButton from './@extended/IconButton';

// assets
import { Discover } from 'iconsax-react';

const DiscoverButton = ({ table_name, id, ...props }) => {
  const [panelVisible, setPanelVisible] = useState(false);

  const handleDrawerOpen = () => {
    setPanelVisible(!panelVisible);
  };

  return (
    <IconButton
      {...props}
      onClick={() => {
        handleDrawerOpen();
      }}
      color="secondary"
    >
      <MovementPanel open={panelVisible} handleDrawerOpen={handleDrawerOpen} table_name={table_name} object_id={id} />
      <Discover variant="Bulk" />
    </IconButton>
  );
};

export default DiscoverButton;
