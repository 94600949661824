import { useState, useEffect } from 'react';

const useUnreadNotificationCount = () => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    const loadValue = () => {
      const savedValue = sessionStorage.getItem('unreadNotificationCount');
      if (savedValue && !isNaN(savedValue)) {
        setValue(parseInt(savedValue, 10));
      } else {
        setValue(0);
      }
    };
    loadValue();
    window.addEventListener('storage', loadValue);
    return () => {
      window.removeEventListener('storage', loadValue);
    };
  }, []);

  return value;
};

export default useUnreadNotificationCount;
