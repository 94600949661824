import React from 'react';
import { CardMedia, ListItemText, Stack } from '@mui/material';
import Avatar from 'components/@extended/Avatar';
import CustomTooltip from 'components/@extended/Tooltip';
import MainCard from 'components/MainCard';

// bigger avatar and name
const TooltipContent = ({ project }) => (
  <MainCard border={false} content={false}>
    {/* put image to card media */}
    <CardMedia component="img" image={process.env.REACT_APP_API_URL + project?.banner_url} alt={project?.name} />

    <ListItemText primaryTypographyProps={{ variant: 'h3', m: 2 }} primary={project?.name} />
  </MainCard>
);

const ProjectCell = ({ project, avatarProps, textProps }) => {
  if (project?.id == null) return null;

  return (
    <CustomTooltip color={'primary'} arrow title={<TooltipContent project={project} />}>
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <Avatar size="sm" src={process.env.REACT_APP_API_URL + project?.banner_url} {...avatarProps}>
          {project.name.charAt(0) + project.name.charAt(1)}
        </Avatar>
        <ListItemText primary={project?.name} {...textProps} />
      </Stack>
    </CustomTooltip>
  );
};

export default ProjectCell;
