import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// material-ui
import { Button, Dialog, Fade, Grid, List, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Stack, Typography } from '@mui/material';

// third-party
import { FormattedMessage } from 'react-intl';

// project-imports
import DocumentPreview from './DocumentPreview';
import AlertDelete from 'sections/apps/alertDelete.js';
import AddDocument from 'sections/apps/document/AddDocument';
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import { PopupTransition } from 'components/@extended/Transitions';
import { useDeleteDocument } from 'hooks/document/useDocumentData';

// assets
import { More, Document } from 'iconsax-react';
import TimestampCell from 'components/data/TimestampCell';
import PublicPrivateCell from 'components/data/PublicPrivateCell';

// ==============================|| DOCUMENT - CARD ||============================== //

export const DocumentIcon = ({ doc, size = 42 }) => {
  if (doc.url == null) {
    return <img alt={doc?.url} style={{ width: size, height: size }} src={'/fileIcons/folder.svg'} />;
  }

  return doc?.url.includes('.pdf') ? (
    <img alt={doc?.url} src={'/fileIcons/img-file-pdf.svg'} />
  ) : doc?.url.includes('.doc') || doc?.url.includes('.docx') ? (
    <img alt={doc?.url} src={'/fileIcons/img-file-doc.svg'} />
  ) : doc?.url.includes('.xls') || doc?.url.includes('.xlsx') ? (
    <img alt={doc?.url} src={'/fileIcons/img-file-xls.svg'} />
  ) : doc?.url.includes('.ppt') || doc?.url.includes('.pptx') ? (
    <img alt={doc?.url} src={'/fileIcons/img-file-ppt.svg'} />
  ) : doc?.url.includes('.zip') || doc?.url.includes('.rar') ? (
    <img alt={doc?.url} src={'/fileIcons/img-file-rar.svg'} />
  ) : doc?.url.includes('.txt') ? (
    <img alt={doc?.url} src={'/fileIcons/img-file-txt.svg'} />
  ) : doc?.url.includes('.img') ||
    doc?.url.includes('.png') ||
    doc?.url.includes('.jpg') ||
    doc?.url.includes('.jpeg') ||
    doc?.url.includes('.webp') ? (
    <img alt={doc?.url} src={'/fileIcons/img-file-img.svg'} />
  ) : (
    <Document style={{ fontSize: '1.15rem' }} />
  );
};

const DocumentCard = ({ doc, setDocumentCardVisible }) => {
  const [open, setOpen] = useState(false);

  const handleMenuItemDownload = () => {
    const anchor = document.createElement('a');
    anchor.href = process.env.REACT_APP_API_URL + doc?.url;
    anchor.target = '_blank';
    anchor.download = doc?.name; // Set the "download" attribute to the file name
    anchor.click();
  };

  const handleClickOpen = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openAlert, setOpenAlert] = useState(false);

  const handleDelete = () => {
    console.log('handleDelete delete here');
    mutate(doc?.id);
  };

  const { mutate, isSuccess } = useDeleteDocument();

  useEffect(() => {
    if (isSuccess) {
      setDocumentCardVisible && setDocumentCardVisible(false);
    }
  }, [isSuccess]);

  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    handleMenuClose();
  };

  const shouldShowPreviewButton =
    doc.is_folder == 0 &&
    !(
      (doc.url.includes('.doc') && !doc.url.includes('.docx')) ||
      doc.url.includes('.xlsx') ||
      doc.url.includes('.xlsm') ||
      doc.url.includes('.xlsb') ||
      doc.url.includes('.xls') ||
      doc.url.includes('.xml') ||
      doc.url.includes('.docm')
    );

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [add, setAdd] = useState(false);
  const handleAdd = () => {
    setAdd(!add);
    handleMenuClose();
  };
  return (
    <>
      <MainCard sx={{ height: 1, '& .MuiCardContent-root': { height: 1, display: 'flex', flexDirection: 'column' } }}>
        <Grid id="print" container spacing={2.25}>
          <Grid item xs={12} xl={12}>
            <List sx={{ width: 1, p: 0 }}>
              <ListItem
                disablePadding
                sx={{ columnGap: 2 }}
                secondaryAction={
                  <IconButton edge="end" aria-label="comments" color="secondary" onClick={handleMenuClick}>
                    <More style={{ fontSize: '1.15rem' }} />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <DocumentIcon doc={doc} />
                </ListItemAvatar>
                <ListItemText
                  sx={{ mr: 3 }}
                  primary={
                    <Typography noWrap variant="subtitle1">
                      {doc?.name}
                    </Typography>
                  }
                  secondary={
                    <Typography noWrap color="text.secondary">
                      {doc?.description}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button'
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
              TransitionComponent={Fade}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  handleMenuItemDownload();
                }}
              >
                <FormattedMessage id="download" />
              </MenuItem>
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  handleAdd();
                }}
              >
                <FormattedMessage id="edit" />
              </MenuItem>
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  handleAlertClose();
                }}
              >
                <FormattedMessage id="delete" />
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
        <Stack
          direction="row"
          className="hideforPDf"
          alignItems="center"
          spacing={1}
          justifyContent="space-between"
          sx={{ mt: 'auto', mb: 0, pt: 2.25 }}
        >
          <PublicPrivateCell value={doc?.is_private} />
          <Stack direction="column">
            <TimestampCell value={doc?.timestamp} detailed={true} />

            <Typography variant="caption" color="text.secondary">
              <FormattedMessage id="size" />: {(doc?.file_size / 1024 / 1024).toFixed(2) + ' MB'}
            </Typography>
          </Stack>
          {shouldShowPreviewButton && (
            <Button variant="outlined" size="small" onClick={handleClickOpen}>
              <FormattedMessage id="preview" />
            </Button>
          )}
        </Stack>
      </MainCard>

      {/* edit doc dialog */}
      <Dialog
        maxWidth="sm"
        fullWidth
        TransitionComponent={PopupTransition}
        onClose={handleAdd}
        open={add}
        sx={{ '& .MuiDialog-paper': { p: 0 } }}
      >
        <AddDocument itemToUpdate={doc} onCancel={handleAdd} />
      </Dialog>
      <DocumentPreview doc={doc} open={open} onClose={handleClose} handleDelete={handleDelete} />
      <AlertDelete
        title={doc?.name + ' (' + doc?.url + ')'}
        open={openAlert}
        handleClose={handleAlertClose}
        handleDelete={handleDelete}
        des1="Emin misiniz?"
        des2="silinecektir."
      />
    </>
  );
};

DocumentCard.propTypes = {
  doc: PropTypes.object
};

export default DocumentCard;
