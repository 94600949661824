import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Stack, Typography, List, ListItem, Box } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import Dot from 'components/@extended/Dot';
import Avatar from 'components/@extended/Avatar';
import { ThemeMode } from 'config';

// third-party
import { FormattedMessage, FormattedRelativeTime } from 'react-intl';

// assets
import { ArrowCircleRight } from 'iconsax-react';
import { getContrastColor } from 'utils/utkuFunctions';
import BuyRequestProductsTable from 'sections/tables/react-table/BuyRequestProductsTable';
import AnimateButton from './@extended/AnimateButton';

// ==============================|| KANBAN BOARD - ITEM updateLog ||============================== //

const formatChange = (key, value) => {
  if (Array.isArray(value)) {
    if (key === 'products') {
      return <BuyRequestProductsTable compact={true} products={value} />;
    }
    // Render array values
    return (
      <List disablePadding>
        {value.map((item, index) => (
          <ListItem disablePadding key={index}>
            <FormattedMessage id={key + '.' + item} defaultMessage={item} />
          </ListItem>
        ))}
      </List>
    );
  } else if (typeof value === 'object' && value !== null) {
    // Render object values
    return (
      <List disablePadding>
        {Object.entries(value).map(([subKey, subValue], index) => (
          <ListItem disablePadding key={index}>
            <Typography variant="body2">{`${subKey}: ${subValue}`}</Typography>
          </ListItem>
        ))}
      </List>
    );
  } else if (key === 'color') {
    // Specific handling for color changes
    return (
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Box sx={{ paddingX: 1, height: 24, backgroundColor: value, borderRadius: 1 }}>
          <Typography color={getContrastColor(value)} variant="body1">
            {value}
          </Typography>
        </Box>
      </Stack>
    );
  } else {
    // Default text display for strings and numbers
    return <Typography variant="body1">{value}</Typography>;
  }
};

const UpdateLogCard = ({ updateLog /*  elementComponent */ }) => {
  const theme = useTheme();
  const user = updateLog.user;
  const changes = updateLog.changes;

  return (
    <MainCard
      content={false}
      sx={{
        background: theme.palette.mode === ThemeMode.DARK ? theme.palette.warning[100] : theme.palette.secondary.lighter,
        p: 1.5
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container wrap="nowrap" alignItems="center" spacing={1}>
            <Grid item>
              <Avatar sx={{ width: 24, height: 24 }} size="sm" alt="User 1" src={process.env.REACT_APP_API_URL + user.avatar_url} />
            </Grid>
            <Grid item xs zeroMinWidth>
              <Grid container alignItems="center" spacing={1} justifyContent="space-between">
                <Grid item>
                  <Typography
                    noWrap
                    align="left"
                    color={theme.palette.primary.main}
                    variant="subtitle1"
                    component="div"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    {user.name}
                    <Typography color={theme.palette.secondary.main} variant="subtitle2" sx={{ ml: 1 }}>
                      güncelledi
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Dot size={6} sx={{ mt: -0.25 }} color="secondary" />
                    <Typography variant="caption" color="secondary">
                      <FormattedRelativeTime
                        value={(new Date(updateLog.timestamp).getTime() - new Date(Date.now()).getTime()) / 1000}
                        updateIntervalInSeconds={1}
                      />
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={12}>
          {elementComponent}
        </Grid> */}
        <Grid item xs={12} sx={{ '&.MuiGrid-root': { pt: 1.5 } }}>
          <Grid container>
            {changes.map((change) => {
              const from = formatChange(change.key, change.from);
              const to = formatChange(change.key, change.to);
              return (
                <Grid key={change.key} item xs={12}>
                  <MainCard content={false} sx={{ p: 1.5, mt: 1.25 }}>
                    <Grid container alignItems="center" spacing={0.2}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">{change.translation}</Typography>
                        <Divider />
                      </Grid>
                      <Grid item xs={5.75}>
                        <Stack alignItems="center" justifyContent="center">
                          {from}
                        </Stack>
                      </Grid>
                      <Grid item xs={0.5}>
                        <Stack direction="row" alignItems="center" justifyContent="center">
                          <AnimateButton type="slide" direction="right" scale={2}>
                            <ArrowCircleRight color={theme.palette.primary.main} variant="Linear" />
                          </AnimateButton>
                        </Stack>
                      </Grid>
                      <Grid item xs={5.75} alignItems={'center'}>
                        <Stack alignItems="center" justifyContent="center">
                          {to}
                        </Stack>
                      </Grid>
                    </Grid>
                  </MainCard>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </MainCard>
  );
};

UpdateLogCard.propTypes = {
  updateLog: PropTypes.object,
  user: PropTypes.object
};

export default UpdateLogCard;
