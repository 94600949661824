import { useState } from 'react';

// material-ui
import { TextField, Autocomplete, ListItem, ListItemText, Chip, Stack, CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project-imports
import { useAutoCompleteCompanies } from 'hooks/company/useCompanyData';
import { useAsyncDebounce } from 'react-table';

// third-party
import { getIn } from 'formik';

export const companyColor = (type) => {
  switch (type) {
    case 'Ana Firma':
      return 'primary';
    case 'Taşeron':
      return 'warning';
    case 'İdari':
      return 'error';
    case 'Tedarikçi':
      return 'success';
    case 'Ortaklık':
      return 'info';
    default:
      return 'secondary';
  }
};

function CompanySelect({ id, label, selectedCompany, setSelectedCompany, onChange, formik, disabled }) {
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const { isLoading, isFetching, data } = useAutoCompleteCompanies({ search, isFocused });
  const companies = data?.data || [];
  const onSearchWordChange = useAsyncDebounce((value) => {
    setSearch(value);
  }, 200);

  return (
    <>
      <Autocomplete
        disabled={disabled}
        loading={isLoading || isFetching}
        loadingText="Firmalar yükleniyor..."
        id="company-select"
        value={selectedCompany}
        options={companies}
        getOptionLabel={(option) => option.name || ''}
        isOptionEqualToValue={(option) => option.id == selectedCompany?.id}
        selectOnFocus
        clearOnBlur
        autoHighlight
        handleHomeEndKeys
        onChange={(event, newValue) => {
          if (onChange) {
            onChange(newValue);
          }
          setSelectedCompany && setSelectedCompany(newValue);
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          setSearch('');
        }}
        filterOptions={(options) => {
          return options;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label || ''}
            placeholder={/* data?.total + ' kayıtlı firma içinden seç' */ 'Firma Ara'}
            value={params.inputProps.value}
            onChange={(e) => onSearchWordChange(e.target.value)}
            InputProps={{
              ...params.InputProps,
              startAdornment: selectedCompany && (
                <Chip label={<Typography>{selectedCompany.code}</Typography>} color={companyColor(selectedCompany.type)} />
              ),
              endAdornment: (
                <>
                  {isLoading || isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
            sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
            error={getIn(formik?.errors, id) && getIn(formik.touched, id)}
            helperText={getIn(formik?.errors, id) && getIn(formik.touched, id) && getIn(formik?.errors, id)}
          />
        )}
        renderOption={(props, option) => (
          <ListItem {...props}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" columnGap={1} width="100%">
              <Stack direction="row" alignItems="center" columnGap={1}>
                <Chip label={<Typography>{option.code}</Typography>} color={companyColor(option.type)} />
                <ListItemText
                  primary={option.name}
                  primaryTypographyProps={{
                    color: theme.palette.text.primary
                  }}
                />
              </Stack>
              <Chip label={<Typography>{option.type}</Typography>} color={companyColor(option.type)} size="small" />
            </Stack>
          </ListItem>
        )}
        noOptionsText="Firma bulunamadı"
      />
    </>
  );
}

export default CompanySelect;
