import React from 'react';
import { Chip } from '@mui/material';

export const currencySymbol = (currencyCode) => {
  switch (currencyCode) {
    case 'TRY':
      return '₺';
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    default:
      return '?';
  }
};

export const CurrencyChip = (currencyCode) => {
  switch (currencyCode) {
    case 'TRY':
      return <Chip label={currencySymbol(currencyCode)} color="primary" />;
    case 'USD':
      return <Chip label={currencySymbol(currencyCode)} color="success" />;
    case 'EUR':
      return <Chip label={currencySymbol(currencyCode)} color="info" />;
    default:
      return <Chip label={currencySymbol(currencyCode)} />;
  }
};
