import { useParams } from 'react-router';
import { useState } from 'react';

// material-ui
import { Dialog, List, ListItemText, Grid, Stack, Typography, Tabs, Tab } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import CompanyCell from 'components/data/CompanyCell';
import { useQuotation } from 'hooks/company/quotations/useCompanyQuotationsData';
import Loader from 'components/Loader';
import ProductQuotasTable from 'sections/tables/react-table/ProductQuotasTable';

// assets
import { Edit, LockCircle } from 'iconsax-react';
import Lottie from 'lottie-react';
import animationData from '../../../assets/lottie/price.json';
import CustomTooltip from 'components/@extended/Tooltip';
import IconButton from 'components/@extended/IconButton';
import DiscoverButton from 'components/DiscoverButton';
import { PopupTransition } from 'components/@extended/Transitions';
import QuotationCreationForm from 'sections/forms/validation/QuotationCreationForm';
import LoadingCard from 'components/LoadingCard';
import ProjectCell from 'components/data/ProjectCell';

const totalsText = {
  total_price: 'Toplam Mal Hizmet Bedeli',
  total_discount: 'Toplam İskonto',
  total_vat: 'Toplam KDV',
  total_tevkifat: 'Toplam Tevkifat',
  grand_total: 'Vergiler Dahil Toplam Tutar',
  net_total: 'Net Ödenecek Tutar'
};

const QuotationDetail = () => {
  const { quotation_id } = useParams();
  const { data: quotationData, isLoading } = useQuotation(quotation_id);

  const [itemToUpdate, setItemToUpdate] = useState(null);
  const [creationVisible, setCreationVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const quotation = quotationData?.data;
  const totals = {
    total_price: quotation?.total_price || 0,
    total_discount: quotation?.total_discount || 0,
    total_vat: quotation?.total_vat || 0,
    total_tevkifat: quotation?.total_tevkifat || 0,
    grand_total: quotation?.grand_total || 0,
    net_total: quotation?.net_total || 0
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  if (isLoading) return <Loader />;

  return (
    <Grid container spacing={3}>
      <Dialog
        maxWidth="xxl"
        fullWidth
        TransitionComponent={PopupTransition}
        onClose={() => {
          setCreationVisible(false);
        }}
        open={creationVisible}
        scroll="body"
        sx={{ '& .MuiDialog-paper': { p: 0 } }}
      >
        <QuotationCreationForm itemToUpdate={itemToUpdate} setCreationVisible={setCreationVisible} />
      </Dialog>

      <Grid item xs={12}>
        <MainCard
          title={
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
              <Typography>{`Ürün Bağlantısı #${quotation?.id}`}</Typography>

              <Stack direction="row" justifyContent="flex-end">
                <CustomTooltip arrow color="default" title="Düzenle">
                  <IconButton
                    onClick={() => {
                      setItemToUpdate(quotation);
                      setCreationVisible(true);
                    }}
                    color="secondary"
                    data-cy={'edit-quotation'}
                  >
                    <Edit />
                  </IconButton>
                </CustomTooltip>
                <DiscoverButton table_name={'quotations'} id={quotation?.id} />
              </Stack>
            </Stack>
          }
        >
          <Grid container spacing={3}>
            {quotation?.remaining_revision > 0 && (
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={4}>
                  <Typography sx={{ fontSize: 24, textAlign: 'center' }}>{'Tedarikçiden Revizyon Talep Edildi'}</Typography>
                  <div style={{ width: 144, height: 144 }}>
                    <Lottie animationData={animationData} loop={true} />
                  </div>
                </Stack>
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="h4">Talep Eden</Typography>
              </Stack>
              <CompanyCell company={quotation?.demanding} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="h4">Tedarikçi</Typography>
              </Stack>
              <CompanyCell company={quotation?.supplier} />
            </Grid>

            <Grid item xs={12}>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                {quotation?.project_quotations.map((projectQuotation, index) => (
                  <Tab data-cy={`quotation-project-${index}`} key={index} label={<ProjectCell project={projectQuotation.project} />} />
                ))}
              </Tabs>

              {quotation?.project_quotations?.length > 0 && (
                <TabPanel value={selectedTab} index={selectedTab}>
                  <Stack direction="row" alignItems="center" spacing={2} mt={4}>
                    <Typography variant="h4">{quotation?.project_quotations[selectedTab]?.project.name}</Typography>
                    {<LockCircle variant="Bulk" />}
                  </Stack>
                  {isLoading ? (
                    <LoadingCard loading={isLoading} />
                  ) : (
                    <ProductQuotasTable data={quotation?.project_quotations[selectedTab]?.products || []} readOnly />
                  )}
                </TabPanel>
              )}
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="h4">Açıklama: </Typography>
                <Typography mt={3} variant="h5">
                  {quotation?.description}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent={'flex-end'} spacing={2}>
                <List>
                  {Object.keys(totals).map((key) => (
                    <ListItemText
                      primaryTypographyProps={{
                        variant: 'h4'
                      }}
                      key={key}
                      primary={parseFloat(totals[key]).toLocaleString('tr-TR', {
                        style: 'currency',
                        currency: 'TRY'
                      })}
                      secondary={totalsText[key]}
                    />
                  ))}
                </List>
              </Stack>
            </Grid>
          </Grid>
        </MainCard>
      </Grid>
    </Grid>
  );
};

const TabPanel = ({ children, value, index }) => {
  return value === index && <>{children}</>;
};

export default QuotationDetail;
