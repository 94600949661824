// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  DocumentCode2,
  Buildings2,
  FolderAdd,
  FolderOpen,
  OceanProtocol,
  Level,
  ShieldCross,
  InfoCircle,
  I24Support,
  Driving,
  Copyright,
  Buildings,
  DocumentText,
  PathTool,
  Calendar,
  Fatrows
} from 'iconsax-react';

// icons
const icons = {
  samplePage: DocumentCode2,
  allProjects: Buildings2,
  menuLevel: OceanProtocol,
  menuLevelSubtitle: Level,
  disabledMenu: ShieldCross,
  chipMenu: InfoCircle,
  documentation: I24Support,
  roadmap: Driving,
  createProject: FolderAdd,
  alldocuments: FolderOpen,
  companies: Buildings,
  company: Copyright,
  contracts: PathTool,
  hakedisler: DocumentText,
  dailyReports: Calendar,
  workProgress: Fatrows
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const project = {
  id: 'project',
  title: <FormattedMessage id="project" />,
  type: 'group',
  children: [
    {
      id: 'projects',
      title: <FormattedMessage id="projects" />,
      type: 'item',
      url: '/projects',
      icon: icons.allProjects
    },
    {
      id: 'documents',
      title: <FormattedMessage id="document-management" />,
      type: 'item',
      url: '/all-documents',
      icon: icons.alldocuments
    },
    {
      id: 'companies',
      title: <FormattedMessage id="companies" />,
      type: 'item',
      url: '/companies',
      icon: icons.company,
      breadcrumbs: true
    },
    {
      id: 'daily-reports',
      title: <FormattedMessage id="daily-reports" />,
      type: 'item',
      url: '/daily-reports',
      icon: icons.dailyReports,
      breadcrumbs: true
    },
    {
      id: 'work-progress',
      title: <FormattedMessage id="work-progress" />,
      type: 'item',
      url: '/work-progress',
      icon: icons.workProgress,
      breadcrumbs: true
    },
    {
      id: 'contracts',
      title: <FormattedMessage id="contracts" />,
      type: 'item',
      url: '/contracts',
      icon: icons.contracts,
      breadcrumbs: true
    },
    {
      id: 'hakedisler',
      title: <FormattedMessage id="process-payment" />,
      type: 'item',
      url: '/process-payments',
      icon: icons.hakedisler,
      breadcrumbs: true
    }
  ]
};

export default project;
