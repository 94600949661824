import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  Chip,
  ListItemText
} from '@mui/material';
import { FieldArray } from 'formik';
import CustomTooltip from 'components/@extended/Tooltip';
import { NumberFormatCustom } from 'utils/utkuFunctions';
import { CurrencyChip, currencySymbol } from 'utils/currencyFunction';

const ExpenseTable = ({ expenses, values, handleChange, handleBlur, touched, errors, readOnly, exchangeRates }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Tip</TableCell>
            <TableCell>Açıklama</TableCell>
            <TableCell>Tutar</TableCell>
            <TableCell>İSKONTO</TableCell>
            <TableCell>KDV</TableCell>
            <TableCell>Tevkifat</TableCell>
            {!readOnly && <TableCell>İşlem</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {readOnly ? (
            expenses.map((expense, index) => (
              <TableRow key={index}>
                <TableCell>{expense.type}</TableCell>
                <TableCell>{expense.description}</TableCell>
                <TableCell>
                  {currencySymbol(expense?.currency_code || 'TRY')}
                  {expense.price.toLocaleString('tr-TR', {
                    style: 'currency',
                    currency: expense?.currency_code || 'TRY'
                  })}
                </TableCell>
                <TableCell>
                  {expense.discount}
                  {expense.discount_type === 'P' ? '%' : currencySymbol(expense?.currency_code || 'TRY')}
                </TableCell>
                <TableCell>{expense.vat}%</TableCell>
                <TableCell>{expense.tevkifat}%</TableCell>
              </TableRow>
            ))
          ) : (
            <FieldArray
              name="expenses"
              render={(arrayHelpers) => (
                <>
                  {values.expenses?.map((expense, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <FormControl sx={{ minWidth: 120 }}>
                          <InputLabel id={`select-expense-type-label-${index}`}>Tip</InputLabel>
                          <Select
                            labelId={`select-expense-type-label-${index}`}
                            disabled={readOnly}
                            name={`expenses[${index}].type`}
                            value={expense.type}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.expenses &&
                              touched.expenses[index] &&
                              touched.expenses[index].type &&
                              Boolean(errors.expenses && errors.expenses[index] && errors.expenses[index].type)
                            }
                          >
                            <MenuItem value="Nakliye">Nakliye</MenuItem>
                            <MenuItem value="Kargo">Kargo</MenuItem>
                            <MenuItem value="Diger">Diğer</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <TextField
                          name={`expenses[${index}].description`}
                          value={expense.description}
                          multiline
                          placeholder="Açıklama"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.expenses &&
                            touched.expenses[index] &&
                            touched.expenses[index].description &&
                            Boolean(errors.expenses && errors.expenses[index] && errors.expenses[index].description)
                          }
                          helperText={
                            touched.expenses &&
                            touched.expenses[index] &&
                            touched.expenses[index].description &&
                            errors.expenses &&
                            errors.expenses[index] &&
                            errors.expenses[index].description
                          }
                          inputProps={{ readOnly }}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          name={`expenses[${index}].price`}
                          value={expense.price}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.expenses &&
                            touched.expenses[index] &&
                            touched.expenses[index].price &&
                            Boolean(errors.expenses && errors.expenses[index] && errors.expenses[index].price)
                          }
                          helperText={
                            touched.expenses &&
                            touched.expenses[index] &&
                            touched.expenses[index].price &&
                            errors.expenses &&
                            errors.expenses[index] &&
                            errors.expenses[index].price
                          }
                          inputProps={{ readOnly }}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                            startAdornment: (
                              <InputAdornment position="start">
                                <FormControl variant="standard">
                                  <Select
                                    value={expense?.currency_code || 'TRY'}
                                    onChange={(newValue) => {
                                      handleChange({
                                        target: {
                                          name: `expenses[${index}].currency_code`,
                                          value: newValue.target.value
                                        }
                                      });
                                    }}
                                    displayEmpty
                                    disableUnderline
                                    inputProps={{ 'aria-label': 'Select currency', readOnly }}
                                    renderValue={(value) => CurrencyChip(value)}
                                    sx={{ minWidth: 20 }}
                                  >
                                    <MenuItem value="" disabled>
                                      <em>Para Birimi</em>
                                    </MenuItem>
                                    <MenuItem value="TRY">
                                      <ListItemText primary="₺" secondary="TRY" />
                                    </MenuItem>
                                    <MenuItem value="USD">
                                      <ListItemText
                                        primary="$"
                                        secondary={`USD (${exchangeRates?.USD?.buy_price?.toLocaleString('tr-TR', {
                                          style: 'currency',
                                          currency: 'TRY'
                                        })})`}
                                      />
                                    </MenuItem>
                                    <MenuItem value="EUR">
                                      <ListItemText
                                        primary="€"
                                        secondary={`EUR (${exchangeRates?.EUR?.buy_price?.toLocaleString('tr-TR', {
                                          style: 'currency',
                                          currency: 'TRY'
                                        })})`}
                                      />
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </InputAdornment>
                            )
                          }}
                        />
                      </TableCell>

                      <TableCell>
                        <TextField
                          name={`expenses[${index}].discount`}
                          value={expense.discount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.expenses &&
                            touched.expenses[index] &&
                            touched.expenses[index].discount &&
                            Boolean(errors.expenses && errors.expenses[index] && errors.expenses[index].discount)
                          }
                          helperText={
                            touched.expenses &&
                            touched.expenses[index] &&
                            touched.expenses[index].discount &&
                            errors.expenses &&
                            errors.expenses[index] &&
                            errors.expenses[index].discount
                          }
                          inputProps={{ readOnly }}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                            endAdornment: (
                              <InputAdornment>
                                <CustomTooltip
                                  color={expense.discount_type === 'P' ? 'primary' : 'success'}
                                  title="Yüzdelik (%) veya Değer ile giriş yapabilirsiniz"
                                  arrow
                                >
                                  <Chip
                                    variant="outlined"
                                    label={expense.discount_type === 'P' ? '%' : currencySymbol(expense?.currency_code || 'TRY')}
                                    color={expense.discount_type === 'P' ? 'primary' : 'success'}
                                    onClick={() => {
                                      if (!readOnly) {
                                        handleChange({
                                          target: {
                                            name: `expenses[${index}].discount_type`,
                                            value: expense.discount_type === 'P' ? 'V' : 'P'
                                          }
                                        });
                                      }
                                    }}
                                  />
                                </CustomTooltip>
                              </InputAdornment>
                            )
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          name={`expenses[${index}].vat`}
                          value={expense.vat}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.expenses &&
                            touched.expenses[index] &&
                            touched.expenses[index].vat &&
                            Boolean(errors.expenses && errors.expenses[index] && errors.expenses[index].vat)
                          }
                          helperText={
                            touched.expenses &&
                            touched.expenses[index] &&
                            touched.expenses[index].vat &&
                            errors.expenses &&
                            errors.expenses[index] &&
                            errors.expenses[index].vat
                          }
                          inputProps={{ readOnly }}
                          InputProps={{
                            endAdornment: <InputAdornment>%</InputAdornment>
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          name={`expenses[${index}].tevkifat`}
                          value={expense.tevkifat}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.expenses &&
                            touched.expenses[index] &&
                            touched.expenses[index].tevkifat &&
                            Boolean(errors.expenses && errors.expenses[index] && errors.expenses[index].tevkifat)
                          }
                          helperText={
                            touched.expenses &&
                            touched.expenses[index] &&
                            touched.expenses[index].tevkifat &&
                            errors.expenses &&
                            errors.expenses[index] &&
                            errors.expenses[index].tevkifat
                          }
                          inputProps={{ readOnly }}
                          InputProps={{
                            endAdornment: <InputAdornment>%</InputAdornment>
                          }}
                        />
                      </TableCell>
                      {!readOnly && (
                        <TableCell>
                          <Button variant="contained" color="secondary" onClick={() => arrayHelpers.remove(index)}>
                            Sil
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                  {!readOnly && (
                    <TableRow>
                      <TableCell colSpan={8} align="right">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            arrayHelpers.push({
                              currency_code: 'TRY',
                              type: '',
                              description: '',
                              price: 0,
                              discount_type: 'P',
                              discount: 0,
                              vat: 20,
                              tevkifat: 0
                            })
                          }
                        >
                          Gider Ekle
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ExpenseTable;
