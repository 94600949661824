import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'utils/axios2';

const fetchComments = () => {
  return axios.get('/api/comments/get_comments');
};

export const useCommentsData = () => {
  return useQuery('comments', fetchComments, { staleTime: 1000, cacheTime: 1000 });
};

const fetchCommentsBy = (body) => {
  return axios.post('/api/comments/get_comments_by', body);
};

export const useCommentsDataBy = (body) => {
  return useQuery(['comments', body], () => fetchCommentsBy(body), { staleTime: 1000, cacheTime: 1000 });
};

const addComment = (comment) => {
  return axios.post('/api/comments', comment);
};

export const useAddComment = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: addComment,
    onSuccess: () => {
      // invalidate movement panel data

      queryClient.invalidateQueries(['comments']);
      queryClient.invalidateQueries(['movement_panel']);
    }
  });
  return mutation;
};

const updateComment = (comment) => {
  return axios.put('/api/comments/update_comment', comment);
};

export const useUpdateComment = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: updateComment,
    onSuccess: () => {
      queryClient.invalidateQueries(['comments']);
    }
  });
  return mutation;
};

const deleteComment = (id) => {
  return axios.delete(`/api/comments/delete_comment`, { id });
};

export const useDeleteComment = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: deleteComment,
    onSuccess: () => {
      queryClient.invalidateQueries(['comments']);
    }
  });
  return mutation;
};
