// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  DocumentCode2,
  Profile2User,
  UserEdit,
  UserAdd,
  UserTag,
  OceanProtocol,
  Level,
  ShieldCross,
  InfoCircle,
  I24Support,
  Driving,
  SecurityUser,
  TagUser,
  BookSaved,
  PresentionChart,
  Data2,
  Key
} from 'iconsax-react';

// icons
const icons = {
  samplePage: DocumentCode2,
  users: TagUser,
  roles: SecurityUser,
  logs: BookSaved,
  chart: PresentionChart,
  list: Data2,
  allUsers: Profile2User,
  editUsers: UserEdit,
  createUsers: UserAdd,
  userDetail: UserTag,
  menuLevel: OceanProtocol,
  menuLevelSubtitle: Level,
  disabledMenu: ShieldCross,
  chipMenu: InfoCircle,
  documentation: I24Support,
  roadmap: Driving,
  access: Key
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const administration = {
  id: 'administration',
  title: <FormattedMessage id="administration" />,
  type: 'group',
  children: [
    {
      id: 'roles',
      title: <FormattedMessage id="roles" />,
      type: 'item',
      icon: icons.roles,
      perms: ['#admin'],
      url: '/roles'
    },
    {
      id: 'users',
      title: <FormattedMessage id="users" />,
      type: 'item',
      icon: icons.allUsers,
      perms: ['#admin'],
      url: '/all-users'
    },

    {
      id: 'logsCharts',
      title: 'Kullanım Grafiği',
      type: 'item',
      url: '/logcharts',
      icon: icons.chart
    }
  ]
};

export default administration;
