// project-imports
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Customization from 'components/Customization';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';
import Joyride from 'react-joyride';
/* import { dispatch } from 'store';
import { fetchMenu } from 'store/reducers/menu'; */

// auth-provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import NoConnectionSnack from 'components/NoConnectionSnack';
/* import { ReactQueryDevtools } from '@tanstack/react-query-devtools'; */

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const joyrideStyles = {
    options: {
      zIndex: 1000000 // Ensure this is higher than any other component
    }
  };

  const steps = [
    /* {
      target: '.bildirimlerButonu',
      content: 'This is my awesome feature!'
    },
    {
      target: '.my-other-step',
      content: 'This another awesome feature!'
    } */
  ];

  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
          console.log('ServiceWorker registration successful with scope: ', registration.scope);
        })
        .catch((error) => {
          console.log('ServiceWorker registration failed: ', error);
        });
    });
  }

  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <QueryClientProvider client={new QueryClient()}>
              <AuthProvider>
                <Joyride steps={steps} continuous={true} scrollToFirstStep={true} showSkipButton={true} styles={joyrideStyles} />
                <>
                  <Notistack>
                    <NoConnectionSnack />
                    <Routes />
                    <Customization />
                    <Snackbar />
                  </Notistack>
                </>
              </AuthProvider>
              <ReactQueryDevtools styleNonce="123124" initialIsOpen={false} panelProps={{ position: 'bottom' }} position="top-right" />
            </QueryClientProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
