import { useEffect, useState } from 'react';
import { Button, Dialog, Grid, InputLabel, Stack, TextField, Tabs, Tab, Box } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import AnimateButton from 'components/@extended/AnimateButton';
import CompanySelect from 'components/select-boxes/CompanySelect';
import ProjectSelect from 'components/select-boxes/ProjectSelect';
import ProjectCell from 'components/data/ProjectCell';
import { useAddCompanyQuotation, useUpdateCompanyQuotation } from 'hooks/company/quotations/useCompanyQuotationsData';

// third-party
import { FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ProductQuotasTable from 'sections/tables/react-table/ProductQuotasTable';
import { useExchangeRatesToday } from 'hooks/exchangeRates/useExchangeRatesData';
import { PopupTransition } from 'components/@extended/Transitions';
import AddProduct from '../validation-wizard/AddProduct';
import { enqueueSnackbar } from 'notistack';

const validationSchema = yup.object({
  demanding: yup.object().required('Talep Eden Firma zorunludur'),
  supplier: yup.object().required('Tedarikçi Firma zorunludur'),
  description: yup.string().max(1000, 'Açıklama 1000 karakterden fazla olamaz')
});

const QuotationCreationForm = ({ company, itemToUpdate, setCreationVisible }) => {
  const { data: exchangeRatesData } = useExchangeRatesToday();
  const exchangeRates = exchangeRatesData?.data;
  const { mutate: addCompany, data: addResponse } = useAddCompanyQuotation();
  const { mutate: updateCompany, data: updateResponse } = useUpdateCompanyQuotation();

  const [projectQuotations, setProjectQuotations] = useState(itemToUpdate?.project_quotations || []);
  const [selectedTab, setSelectedTab] = useState(0);
  const [visible, setVisible] = useState(false);
  const [formikErrors, setFormikErrors] = useState({});
  const [addingProject, setAddingProject] = useState(false);

  const formik = useFormik({
    initialValues: {
      demanding: itemToUpdate?.demanding,
      supplier: itemToUpdate?.supplier || company,
      description: itemToUpdate?.description || ''
    },
    validationSchema,
    onSubmit: () => {
      const payload = {
        demanding_id: formik.values.demanding?.id,
        supplier_id: formik.values.supplier?.id,
        description: formik.values.description,
        product_quotas: projectQuotations.flatMap((project) =>
          project.products.map((product) => ({
            id: product.id,
            project_id: project.project.id,
            product_id: product.product.id,
            vat: product.vat,
            price: product.price,
            discount: product.discount,
            tevkifat: product.tevkifat,
            used_amount: product.used_amount,
            currency_code: product.currency_code,
            discount_type: product.discount_type,
            quotation_amount: product.quotation_amount,
            brand: product.brand,
            model: product.model
          }))
        )
      };

      if (itemToUpdate != undefined) {
        updateCompany({ id: itemToUpdate.id, ...payload });
      } else {
        addCompany(payload);
      }
    }
  });

  useEffect(() => {
    if (addResponse?.success === true || updateResponse?.success === true) {
      setCreationVisible(false);
    }
  }, [addResponse, updateResponse]);

  const handleAddProduct = (product) => {
    const project = projectQuotations[selectedTab];

    if (!project) {
      enqueueSnackbar('Lütfen önce bir proje ekleyin.', { variant: 'error' });
      return;
    }

    const isExist = project.products.find((item) => item.product.id === product.product.id);
    if (isExist) {
      enqueueSnackbar('Ürün zaten ekli', { variant: 'error' });
    } else {
      setProjectQuotations((prev) => {
        const updated = [...prev];
        updated[selectedTab].products.push(product);
        return updated;
      });
      setVisible(false);
    }
  };

  const handleDeleteRow = (row) => {
    setProjectQuotations((prev) => {
      const updated = [...prev];
      updated[selectedTab].products = updated[selectedTab].products.filter((item) => item.product.id !== row.product.id);
      return updated;
    });
  };

  const [quotationProject, setQuotationProject] = useState(null);

  const handleTabChange = (event, newValue) => {
    if (newValue === projectQuotations.length) {
      setAddingProject(true);
      setQuotationProject(null);
    } else {
      setAddingProject(false);
      setSelectedTab(newValue);
      setQuotationProject(projectQuotations[newValue]?.project);
    }
  };
  console.log('selectedTab', selectedTab);
  console.log('quotationProject', quotationProject);

  const handleAddProject = (project) => {
    if (!projectQuotations.some((pq) => pq.project.id === project.id)) {
      setProjectQuotations((prev) => [...prev, { project, products: [] }]);
      setAddingProject(false);
      setSelectedTab(projectQuotations.length); // Switch to the new tab after adding
      setQuotationProject(project);
    } else {
      enqueueSnackbar('Bu proje zaten ekli', { variant: 'error' });
    }
  };

  useEffect(() => {
    handleTabChange(null, 0);
  }, []);

  return (
    <MainCard title={itemToUpdate != undefined ? 'Ürün Bağlantısı Güncelle' : 'Ürün Bağlantısı Tanımlama'}>
      <Dialog
        maxWidth="sm"
        fullWidth
        TransitionComponent={PopupTransition}
        onClose={() => setVisible(false)}
        scroll="body"
        open={visible}
        sx={{ '& .MuiDialog-paper': { p: 0 } }}
      >
        <AddProduct onlyQuotas quotationProject={quotationProject} setVisible={setVisible} handleAddProduct={handleAddProduct} />
      </Dialog>

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <Stack spacing={1}>
              <InputLabel>Talep Eden Firma</InputLabel>
              <CompanySelect
                id={'demanding'}
                formik={formik}
                selectedCompany={formik.values.demanding}
                setSelectedCompany={(value) => formik.setFieldValue('demanding', value)}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Stack spacing={1}>
              <InputLabel>Tedarikçi Firma</InputLabel>
              <CompanySelect
                id={'supplier'}
                formik={formik}
                selectedCompany={formik.values.supplier}
                setSelectedCompany={(value) => formik.setFieldValue('supplier', value)}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Stack spacing={1}>
              <InputLabel>Proje ve Ürünler</InputLabel>
              <Tabs
                value={addingProject ? projectQuotations.length : selectedTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                {projectQuotations.map((project, index) => (
                  <Tab data-cy={`quotation-project-edit-tab-${index}`} key={index} label={<ProjectCell project={project.project} />} />
                ))}
                <Tab data-cy={`quotation-project-edit-tab-${projectQuotations?.length}`} label="Proje Ekle" />
              </Tabs>

              {addingProject ? (
                <Box sx={{ p: 3, width: 300 }}>
                  <ProjectSelect selectedProject={null} id={'project'} onChange={handleAddProject} />
                </Box>
              ) : (
                projectQuotations[selectedTab] && (
                  <ProductQuotasTable
                    data={projectQuotations[selectedTab]?.products || []}
                    setData={(newData) =>
                      setProjectQuotations((prev) => {
                        const updated = [...prev];
                        updated[selectedTab].products = newData;
                        return updated;
                      })
                    }
                    setFormikErrors={setFormikErrors}
                    exchangeRates={exchangeRates}
                    deleteRow={handleDeleteRow}
                  />
                )
              )}

              {!addingProject && projectQuotations[selectedTab] && (
                <Button onClick={() => setVisible(true)} variant="contained" color="primary" sx={{ maxWidth: 100, alignSelf: 'flex-end' }}>
                  Ürün Ekle
                </Button>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Stack spacing={1}>
              <InputLabel>Açıklama</InputLabel>
              <TextField
                autoComplete="off"
                fullWidth
                id="description"
                name="description"
                multiline
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <AnimateButton>
                <Button
                  data-cy="submit-quotation"
                  disabled={projectQuotations.length < 1 || Object.keys(formikErrors).length > 0}
                  variant="contained"
                  type="submit"
                  color="primary"
                >
                  <FormattedMessage id={itemToUpdate != undefined ? 'update' : 'add'} />
                </Button>
              </AnimateButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
};

export default QuotationCreationForm;
