import React from 'react';

// material-ui
import { Typography, Box, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const FilterResultsDisplay = ({ total, filteredTotal, executionTime, isFetching }) => {
  const theme = useTheme();
  return isFetching ? (
    <Typography variant="subtitle1" color="inherit" sx={{ color: theme.palette.text.secondary }}>
      <CircularProgress size={16} thickness={12} sx={{ marginRight: 1 }} />
      Filtreleniyor...
    </Typography>
  ) : (
    <Typography variant="subtitle1" color="inherit" sx={{ color: theme.palette.text.secondary }}>
      <Box component="span" color={theme.palette.primary.main}>
        {total}
      </Box>
      {` kayıt arasından `}
      <Box component="span" color={theme.palette.success.main}>
        {filteredTotal}
      </Box>
      {` filtrelendi. ( ${(executionTime / 1000).toFixed(1)} sn )`}
    </Typography>
  );
};

export default FilterResultsDisplay;
