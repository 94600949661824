import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Grid, TextField } from '@mui/material';

// third-party
import { FormattedMessage } from 'react-intl';

// project-imports
import IconButton from 'components/@extended/IconButton';
import { useAddComment } from 'hooks/comment/useCommentsData';

// assets
import { Android, Camera, DocumentUpload } from 'iconsax-react';
import { enqueueSnackbar } from 'notistack';

// ==============================|| KANBAN BOARD - ADD ITEM COMMENT ||============================== //

const AddComment = ({ table_name, object_id }) => {
  const theme = useTheme();

  const [comment, setComment] = useState('');
  const [isComment, setIsComment] = useState(false);

  const { mutate: addComment } = useAddComment();

  const handleAddTaskComment = (event) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      handleAddComment();
    }
  };

  const handleAddComment = () => {
    if (comment.length > 0) {
      const newComment = {
        table_name,
        object_id,
        content: comment
      };
      addComment(newComment);
      setComment('');
    } else {
      setIsComment(true);
    }
  };

  const handleCommentContent = (event) => {
    const newComment = event.target.value;
    setComment(newComment);
    if (newComment.length <= 0) {
      setIsComment(true);
    } else {
      setIsComment(false);
    }
  };

  const handleClickVip = () => {
    enqueueSnackbar('Bu özellik henüz tamamlanmadı', { variant: 'info' });
  };

  return (
    <Box sx={{ p: 2, pb: 1.5, border: `1px solid ${theme.palette.divider}` }}>
      <Grid container alignItems="center" spacing={0.5}>
        <Grid item xs={12}>
          <TextField
            onClick={(event) => {
              // disable modal closing when clicking on the textfield
              event.stopPropagation();
            }}
            /* fullWidth */
            placeholder="Yorum ekleyin"
            value={comment}
            onChange={handleCommentContent}
            sx={{
              mb: 3,
              '& input': { bgcolor: 'transparent', p: 0, borderRadius: '0px' },
              '& fieldset': { display: 'none' },
              '& .MuiFormHelperText-root': {
                ml: 0
              },
              '& .MuiOutlinedInput-root': {
                bgcolor: 'transparent',
                '&.Mui-focused': {
                  boxShadow: 'none'
                }
              }
            }}
            onKeyUp={handleAddTaskComment}
            helperText={isComment ? 'Yorum metni gerekli.' : ''}
            error={isComment}
          />
        </Grid>
        <Grid item>
          <IconButton onClick={handleClickVip}>
            <Camera />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton onClick={handleClickVip}>
            <DocumentUpload />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton onClick={handleClickVip}>
            <Android />
          </IconButton>
        </Grid>
        <Grid item xs zeroMinWidth />
        <Grid item>
          <Button size="small" variant="contained" color="primary" onClick={handleAddComment}>
            <FormattedMessage id="save" />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

AddComment.propTypes = {
  itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default AddComment;
