/* import { AES, enc, mode, pad } from 'crypto-js'; */
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';

// project-imports
import { useOfferForm } from 'hooks/offers/useOffersData';
import MainCard from 'components/MainCard';
import OfferedProductsTable from 'sections/tables/react-table/OfferedProductsTable';
import ExpenseTable from 'sections/tables/react-table/ExpenseTable';
import { Button, Grid, InputLabel, List, ListItemText, MenuItem, Select, Stack, Typography, TextField } from '@mui/material';
import { useUpdateOffer, useUpdateOfferWithToken } from 'hooks/offers/useOffersData';
import ProjectCell from 'components/data/ProjectCell';
import CompanyCell from 'components/data/CompanyCell';
import { paymentTypes } from 'data/paymentTypes';
import Loader from 'components/Loader';
import { totalsText } from './create-offer';
import { offerValidation } from './create-offer';
import { offer_calculation } from './create-offer';

// third-party
import { FormikProvider, useFormik } from 'formik';

import Lottie from 'lottie-react';
import animationData from '../../../assets/lottie/locked.json';
import animationData2 from '../../../assets/lottie/revision.json';
import useAuth from 'hooks/useAuth';

const EditOffer = () => {
  const { user } = useAuth();
  const isUser = typeof user?.id !== 'undefined';
  const { offer_token } = useParams();

  const navigate = useNavigate();
  const { data: offerForm, isLoading: formLoading } = useOfferForm(offer_token);

  const existingOffer = offerForm?.data?.offer;

  const [requestedProducts, setRequestedProducts] = useState(offerForm?.data?.requestedProducts || offerForm?.data?.offered_products || []);

  const [offeredProducts, setOfferedProducts] = useState([]);
  const method = offerForm?.method;

  const { mutate: updateOffer, data: updateResponse } = useUpdateOffer();
  const { mutate: updateOfferWithToken, data: updateWithTokenResponse } = useUpdateOfferWithToken();
  const readOnly = isUser ? false : existingOffer?.remaining_revision == 0;
  const [totals, setTotals] = useState({
    total_price: 0,
    total_discount: 0,
    total_vat: 0,
    total_tevkifat: 0,
    grand_total: 0,
    net_total: 0
  });
  const [formikErrors, setFormikErrors] = useState({});

  const formik = useFormik({
    initialValues: {
      payment_type: existingOffer?.payment_type || '',
      description: existingOffer?.description || '',
      expenses: existingOffer?.expenses || []
    },
    validationSchema: offerValidation,
    onSubmit: (values) => {
      const payload = {
        id: existingOffer?.id,
        offer_token,
        payment_type: values.payment_type,
        description: values.description,
        expenses: values.expenses,
        offered_products: offeredProducts
      };
      if (isUser) {
        updateOffer(payload);
      } else {
        updateOfferWithToken(payload);
      }
    }
  });

  useEffect(() => {
    offerForm?.data?.requestedProducts && setRequestedProducts(offerForm?.data?.requestedProducts || []);
  }, [offerForm?.data?.requestedProducts]);

  useEffect(() => {
    if (offerForm?.data?.offered_products) {
      setRequestedProducts(offerForm?.data?.offered_products || []);
      setOfferedProducts(offerForm?.data?.offered_products || []);
    }
  }, [offerForm?.data?.offered_products]);

  useEffect(() => {
    formik.setFieldValue('payment_type', existingOffer?.payment_type || '');
    formik.setFieldValue('description', existingOffer?.description || '');
    formik.setFieldValue('expenses', existingOffer?.expenses || []);
  }, [existingOffer]);

  console.log('formik.values', formik.values);

  useEffect(() => {
    const totals = offer_calculation(offeredProducts, formik.values.expenses, offerForm?.data?.exchangeRates);
    setTotals(totals);
  }, [offeredProducts, formik.values.expenses]);

  useEffect(() => {
    if (offerForm?.message === 'This offer token is already used to create an offer') {
      if (method === 'mail') {
        /* navigate('/offer-exists'); */
      } else if (method === 'manual') {
        const existingOffer = offerForm?.data?.offer;
        console.log('existingOffer', existingOffer);
      }
    }
  }, [offerForm]);

  useEffect(() => {
    if (updateResponse?.success) {
      navigate(`/offers/${existingOffer?.id}`);
    }
  }, [updateResponse]);

  useEffect(() => {
    console.log('updateWithTokenResponse', updateWithTokenResponse);
    if (updateWithTokenResponse?.success) {
      navigate(`/fill-offer/${existingOffer?.offer_token || updateWithTokenResponse?.offer_token}`);
    }
  }, [updateWithTokenResponse]);

  const urlIsCreateOffer = window.location.href.includes('create-offer');

  const renderForm = urlIsCreateOffer ? (existingOffer == null ? true : false) : true;
  console.log('_formik.errors', formik.errors);
  return (
    <FormikProvider value={formik}>
      {formLoading && <Loader />}
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          {renderForm && (
            <MainCard sx={{ pointer: 'none' }} title={'Teklif Düzenle'}>
              <Grid container padding={2} spacing={4}>
                {!isUser && (
                  <Grid item xs={12}>
                    {existingOffer?.remaining_revision < 1 ? (
                      <Stack direction="row" alignItems="center" justifyContent="center" spacing={4}>
                        <Typography sx={{ fontSize: 24, textAlign: 'center' }}>
                          Teklifinizde düzenleme yapmak için lütfen revizyon talebi isteyiniz.
                        </Typography>
                        <div style={{ width: 280, height: 280 }}>
                          <Lottie animationData={animationData} loop={true} />
                        </div>
                      </Stack>
                    ) : (
                      <Stack direction="row" alignItems="center" justifyContent="center" spacing={4}>
                        <Typography sx={{ fontSize: 42, textAlign: 'center' }}>
                          Kalan revizyon hakkınız: {existingOffer?.remaining_revision}
                        </Typography>
                        <div style={{ width: 280, height: 280 }}>
                          <Lottie animationData={animationData2} loop={true} />
                        </div>
                      </Stack>
                    )}
                  </Grid>
                )}
                <Grid item xs={12} lg={3}>
                  <Typography variant="h5">Proje</Typography>
                  <ProjectCell
                    project={offerForm?.data?.project || existingOffer?.project}
                    avatarProps={{ size: 'md' }}
                    textProps={{ primaryTypographyProps: { variant: 'h5' } }}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Typography variant="h5">Talep Eden Firma</Typography>
                  <CompanyCell company={offerForm?.data?.demanding || existingOffer?.demanding} maxWidth={400} />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Typography variant="h5">Tedarikçi Firma</Typography>
                  <CompanyCell company={offerForm?.data?.supplier || existingOffer?.supplier} maxWidth={400} />
                </Grid>

                <Grid item xs={12}>
                  <Stack spacing={2}>
                    {requestedProducts.length > 0 && (
                      <OfferedProductsTable
                        setFormikErrors={setFormikErrors}
                        data={offeredProducts}
                        readOnly={readOnly}
                        exchangeRates={offerForm?.data?.exchangeRates}
                        setOfferedProducts={setOfferedProducts}
                      />
                    )}

                    <InputLabel>Ekstra Giderler</InputLabel>

                    <ExpenseTable
                      exchangeRates={offerForm?.data?.exchangeRates}
                      readOnly={readOnly}
                      values={formik.values}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      touched={formik.touched}
                      errors={formik.errors}
                    />

                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={3}>
                        <InputLabel>Ödeme Tipi</InputLabel>
                        <Stack direction="row" justifyContent={'flex-end'} spacing={2}>
                          <Select
                            id="payment_type"
                            name="payment_type"
                            data-cy="payment-type-select"
                            placeholder="Ödeme Tipi Seçiniz"
                            disabled={readOnly}
                            value={formik.values.payment_type}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.payment_type && Boolean(formik.errors.payment_type)}
                            fullWidth
                          >
                            {paymentTypes.map((type) => (
                              <MenuItem id={type} key={type.name} value={type.name}>
                                {type.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} lg={9}>
                        <InputLabel>Açıklama</InputLabel>
                        <Stack direction="row" justifyContent={'flex-end'} spacing={2}>
                          <TextField
                            disabled={readOnly}
                            name="description"
                            placeholder="Ör: Vade 30 gündür"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            fullWidth
                          />
                        </Stack>
                      </Grid>
                    </Grid>

                    <Stack direction="row" justifyContent={'flex-end'} spacing={2}>
                      <List>
                        {Object.keys(totals).map((key) => (
                          <ListItemText
                            primaryTypographyProps={{
                              variant: 'h4'
                            }}
                            key={key}
                            primary={
                              isNaN(totals[key])
                                ? '...'
                                : parseFloat(totals[key]).toLocaleString('tr-TR', {
                                    style: 'currency',
                                    currency: 'TRY'
                                  })
                            }
                            secondary={totalsText[key]}
                          />
                        ))}
                      </List>
                    </Stack>
                    <Stack direction="row" justifyContent={'flex-end'} spacing={2}>
                      <Button
                        id="submit-update-offer"
                        disabled={readOnly || Object.keys(formikErrors).length > 0}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Teklifi Güncelle
                      </Button>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </MainCard>
          )}
        </Stack>
      </form>
    </FormikProvider>
  );
};

export default EditOffer;
