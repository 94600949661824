import React from 'react';
import { Typography, Chip, Stack, ListItemText } from '@mui/material';

import { FormattedNumber } from 'react-intl';

import { companyColor } from 'components/select-boxes/CompanySelect';
import CustomTooltip from 'components/@extended/Tooltip';

const CompanyCodeChip = ({ company }) => {
  return (
    <CustomTooltip title={company.name} arrow color={companyColor(company?.type)}>
      <Chip size="sm" label={<Typography>{company?.code}</Typography>} color={companyColor(company?.type)} />
    </CustomTooltip>
  );
};
const CompanyCell = ({ company, onlyCode, maxWidth }) => {
  if (!company) return null;
  if (onlyCode) return <CompanyCodeChip company={company} />;
  return (
    <Stack direction="row" alignItems={'center'} columnGap={1} sx={{ maxWidth: maxWidth || null }}>
      <CompanyCodeChip company={company} />
      <Typography noWrap={true}>{company.name}</Typography>
      {company.stats_____ && (
        <Stack direction="row" alignItems={'center'} spacing={4} pl={4}>
          <ListItemText primary={company.stats.total_offers} secondary="Verilen Teklifler" />
          <ListItemText
            primary={
              <FormattedNumber
                value={company.stats.grand_total}
                style="currency"
                currency="TRY"
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            }
            secondary="Toplam Hacim"
          />
        </Stack>
      )}
    </Stack>
  );
};

export default CompanyCell;
