import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'utils/axios2';

const fetchOffer = async (id) => {
  const { data } = await axios.get(`/api/offers/${id}`);
  return data;
};

export const useOffer = (id) => {
  return useQuery(['offers', id], () => fetchOffer(id), {
    enabled: !!id,
    cacheTime: 0.2 * 60 * 1000,
    staleTime: 0.2 * 60 * 1000,
    keepPreviousData: false
  });
};

const fetchOffers = async (params = {}) => {
  const queryParams = new URLSearchParams(
    Object.entries(params).reduce((acc, [key, value]) => {
      if (value != null) {
        acc[key] = value;
      }
      return acc;
    }, {})
  ).toString();

  const { data } = await axios.get(`/api/offers${queryParams ? `?${queryParams}` : ''}`);
  return data;
};

export const useOffers = (params) => {
  return useQuery(['offers', params], () => fetchOffers(params), {
    cacheTime: 1 * 60 * 1000,
    staleTime: 1 * 60 * 1000,
    keepPreviousData: true
  });
};

const deleteOffer = async (id) => {
  return await axios.delete(`/api/offers/${id}`);
};

export const useDeleteOffer = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteOffer, {
    onSuccess: () => {
      queryClient.invalidateQueries('offers');
    }
  });
};

const sendOfferEmail = (request) => {
  return axios.post('api/offers/send', request);
};

export const useSendOfferEmail = () => {
  const queryClient = useQueryClient();
  return useMutation(sendOfferEmail, {
    onSuccess: () => {
      queryClient.invalidateQueries('offers');
    }
  });
};

const fetchOfferForm = async (offer_token) => {
  const { data } = await axios.get(`/api/offers/form/${offer_token}`);
  return data;
};

export const useOfferForm = (offer_token) => {
  return useQuery(['offer-form', offer_token], () => fetchOfferForm(offer_token), {
    enabled: offer_token != undefined,
    cacheTime: 0.2 * 60 * 1000,
    staleTime: 0.2 * 60 * 1000,
    keepPreviousData: false
  });
};

const createOffer = async (payload) => {
  const { data } = await axios.post('/api/offers', payload);
  return data;
};

export const useCreateOffer = () => {
  const queryClient = useQueryClient();
  return useMutation(createOffer, {
    onSuccess: () => {
      queryClient.invalidateQueries('offers');
      queryClient.invalidateQueries('offer-form');
    }
  });
};

const createOfferWithToken = async (payload) => {
  const { data } = await axios.post('/api/offers/fill', payload);
  return data;
};

export const useCreateOfferWithToken = () => {
  const queryClient = useQueryClient();
  return useMutation(createOfferWithToken, {
    onSuccess: () => {
      queryClient.invalidateQueries('offers');
      queryClient.invalidateQueries('offer-form');
    }
  });
};

const updateOffer = async (payload) => {
  const { data } = await axios.put(`/api/offers/${payload.id}`, payload);
  return data;
};

export const useUpdateOffer = () => {
  const queryClient = useQueryClient();
  return useMutation(updateOffer, {
    onSuccess: () => {
      queryClient.invalidateQueries('offers');
      queryClient.invalidateQueries('offer-form');
    }
  });
};

const updateOfferWithToken = async (payload) => {
  const { data } = await axios.put(`/api/offers/${payload.id}/revision`, payload);
  return data;
};

export const useUpdateOfferWithToken = () => {
  const queryClient = useQueryClient();
  return useMutation(updateOfferWithToken, {
    onSuccess: () => {
      queryClient.invalidateQueries('offers');
      queryClient.invalidateQueries('offer-form');
    }
  });
};

const setRemainingRevision = async (payload) => {
  const { data } = await axios.put(`/api/offers/${payload.id}/remaining_revision`, payload);
  return data;
};

export const useSetRemainingRevision = () => {
  const queryClient = useQueryClient();
  return useMutation(setRemainingRevision, {
    onSuccess: () => {
      queryClient.invalidateQueries('offers');
      queryClient.invalidateQueries('offer-form');
    }
  });
};
