import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// material-ui
import { Badge, Button, Chip, Grid, Stack, Typography } from '@mui/material';

// project-imports,
import Avatar from 'components/@extended/Avatar';
import TimeagoCell from 'components/data/TimeagoCell';
import { useReadNotification } from 'hooks/notification/useNotificationsData';
import MainCard from 'components/MainCard';
import { notificationTypes } from 'data/notificationTypes';

// assets
import cardBack from 'assets/images/widget/img-dropbox-bg.svg';
import AnimateButton from 'components/@extended/AnimateButton';

const NotificationCard = ({ notification }) => {
  const navigate = useNavigate();
  const actions = notification.actions; /* || [
    {
      label: `Git ${notification?.table_name} #${notification?.object_id}`,
      button: { variant: 'contained', size: 'small', color: 'primary', onMouseDown: (e) => e.stopPropagation() }
    }
  ]; */
  const { mutate: readNotification } = useReadNotification();

  const { color, icon, label } = notificationTypes[notification.type];
  const type = notification.type;
  const seen = notification.seen === '1';
  const handleClick = () => {
    /* readNotification(notification.id); */

    switch (type) {
      case 'message':
        navigate('/chat', { state: { notification } });
        break;
      /*  case 'group_message':
        navigate('/chat', { state: { notification } });
        break;
        */

      case 'abcdefghijlmknopqrstuvwxyz':
        readNotification(notification.id);
        break;
      default:
        if (notification?.table_name && notification?.object_id) {
          if (notification?.table_name === 'buy_requests') {
            navigate(`/buy-requests/${notification?.object_id}`, { state: { notification } });
          } else {
            navigate(`/${notification?.table_name}/${notification?.object_id}`, { state: { notification } });
          }
        }
        break;
    }
  };
  return (
    <MainCard
      boxShadow
      shadow={'3'}
      sx={{
        cursor: 'pointer',
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? 'secondary.100' : 'background.paper'),

        '&:hover': {
          bgcolor: (theme) => (theme.palette.mode === 'dark' ? 'secondary.200' : 'secondary.200')
        },

        '&:after': {
          content: '""',
          background: `url("${cardBack}") 100% / cover no-repeat`,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
          opacity: (theme) => (seen ? (theme.palette.mode === 'dark' ? 0.2 : 0) : theme.palette.mode === 'dark' ? 0.2 : 0)
        }
        /* opacity: seen ? 0.6 : 1 */
      }}
      onClick={handleClick}
    >
      <Grid container spacing={0.5}>
        {notification.seen == '0' && <Badge badgeContent={1} color={'error'} />}
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" justifyContent={'space-between'} spacing={1}>
            <Stack direction={'row'} alignItems="center" spacing={1}>
              <Avatar size="sm" src={process.env.REACT_APP_API_URL + notification.user?.avatar_url}>
                {notification.user?.name}
              </Avatar>
              <Typography color={'contrastText'} variant="caption">
                <TimeagoCell value={notification.timestamp} />
              </Typography>
            </Stack>
            <Chip sx={{ zIndex: 9 }} label={label} icon={icon} color={color} size="small" variant={'filled'} />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              zIndex: 9
            }}
            textAlign={'left'}
            variant="subtitle1"
          >
            {notification.title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              zIndex: 9
            }}
            variant="body2"
            textAlign={'left'}
          >
            {notification.body}
          </Typography>
        </Grid>

        {actions && actions?.length > 0 && (
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Stack direction="row" alignItems="center" spacing={{ xs: 1.5, sm: 2.5 }}>
              {actions.map((item, index) => (
                <AnimateButton scale={{ hover: 1.4 }} key={index}>
                  <Button {...item.button} key={index}>
                    {item.label}
                  </Button>
                </AnimateButton>
              ))}
            </Stack>
          </Grid>
        )}
      </Grid>
    </MainCard>
  );
};

NotificationCard.propTypes = {
  notification: PropTypes.object.isRequired
};

export default NotificationCard;
