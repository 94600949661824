import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'utils/axios2';

// Function to fetch buy requests with optional query parameters
const fetchDocuments = async (params = {}) => {
  // Construct query parameters string, filtering out undefined or null values
  const queryParams = new URLSearchParams(
    Object.entries(params).reduce((acc, [key, value]) => {
      if (value != null) {
        // Check for null or undefined
        acc[key] = value;
      }
      return acc;
    }, {})
  ).toString();

  // Call the API with the constructed query string
  const { data } = await axios.get(`/api/documents${queryParams ? `?${queryParams}` : ''}`);
  return data;
};

// React Query hook for using the fetch function in a component
export const useDocumentsData = (params) => {
  return useQuery(['documents', params], () => fetchDocuments(params), {
    cacheTime: 1 * 60 * 1000,
    staleTime: 1 * 60 * 1000,
    keepPreviousData: true
  });
};

const uploadDocument = ({ payload, onProgress }) => {
  console.log('useUploadDocument payload', payload);
  console.log('payload?.is_folder == 1', payload?.is_folder == 1);

  if (payload?.is_folder == 1) {
    return axios.post('/api/documents', payload);
  } else {
    const formData = new FormData();

    // for every key in payload, append it to formData
    Object.keys(payload).forEach((key) => {
      console.log('append', key, payload[key]);
      formData.append(key, payload[key]);
    });

    return axios.post('/api/documents', formData, {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress(percentCompleted);
      }
    });
  }
};

export const useUploadDocument = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(uploadDocument, {
    onSuccess: () => {
      queryClient.invalidateQueries(['documents']);
    }
  });

  return mutation;
};

const updateDocument = (payload) => {
  return axios.put(`/api/documents/${payload.id}`, payload);
};

export const useUpdateDocument = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateDocument, {
    onSuccess: () => {
      queryClient.invalidateQueries(['documents']);
    }
  });

  return mutation;
};

const deleteDocument = (id) => {
  return axios.delete(`/api/documents/${id}`);
};

export const useDeleteDocument = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteDocument, {
    onSuccess: () => {
      queryClient.invalidateQueries(['documents']);
    }
  });
  return mutation;
};
