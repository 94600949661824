import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Badge, Box, Button, CircularProgress, Drawer, FormControlLabel, Grid, Stack, Switch, Tooltip, Typography } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import SimpleBar from 'components/third-party/SimpleBar';
import { ThemeMode } from 'config';
import { useMyNotifications, useReadAllNotifications, useSubscribe, useUnsubscribe } from 'hooks/notification/useNotificationsData';
import NotificationCard from 'components/cards/item/NotificationCard';
import NotificationCardSkeleton from 'components/cards/skeleton/NotificationCardSkeleton';

// assets
import { Add, Notification as NotificationIcon, Setting2 } from 'iconsax-react';
import { enqueueSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';

// third-party
import FingerprintJS from '@fingerprintjs/fingerprintjs';
async function getDeviceFingerprint() {
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  return result.visitorId;
}

// ==============================|| HEADER CONTENT - MyNotifications ||============================== //

const MyNotifications = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { mutate: subscribe } = useSubscribe();
  const { mutate: unsubscribe } = useUnsubscribe();
  const { mutate: readAllNotifications } = useReadAllNotifications();
  const { data: myNotificationsData, isLoading, isFetching } = useMyNotifications();
  const myNotifications = myNotificationsData?.data?.data || [1, 2, 3, 4, 5];
  const unreadCount = myNotificationsData?.data?.unreadCount || 0;
  const userEndpoints = myNotificationsData?.data?.endpoints || [];
  const [isSubscribed, setIsSubscribed] = useState(userEndpoints.length > 0);

  useEffect(() => {
    sessionStorage.setItem('unreadNotificationCount', unreadCount);
    window.dispatchEvent(new Event('storage'));
  }, [unreadCount]);

  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
    checkNotificationPermission();
    checkSubscription();
  };
  function askForPermission() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then(function (registration) {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch(function (error) {
          console.error('Service Worker registration failed:', error);
        });
    }
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        // Servis çalışanını kaydet
        navigator.serviceWorker.ready
          .then(function (registration) {
            console.log('Service Worker is ready:', registration);
            subscribeUserToPush(registration);
          })
          .catch(function (error) {
            console.error('Service Worker ready error:', error);
          });
      } else {
        console.log('Notification permission denied.');
      }
    });
  }

  function checkNotificationPermission() {
    if (Notification.permission === 'denied') {
      console.log('Notification permission denied. 123');
      enqueueSnackbar('Bildirimler engellendi. Lütfen tarayıcınızın ayarlarından bildirimleri etkinleştirin.', {
        variant: 'info',
        preventDuplicate: true
      });

      return;
    }
    if (Notification.permission === 'default') {
      /* console.log('Notification permission default.'); */
      // and invalidate the myNotifications query
      queryClient.invalidateQueries('myNotifications');
      return;
    }
    if (Notification.permission === 'granted') {
      /* console.log('Notification permission granted.'); */
      return;
    }
  }

  function checkSubscription() {
    navigator.serviceWorker.ready
      .then(function (registration) {
        registration.pushManager
          .getSubscription()
          .then(function (subscription) {
            if (!subscription) {
              /*  console.log('User is NOT subscribed.'); */
              setIsSubscribed(false);

              return;
            } else if (userEndpoints.includes(subscription.endpoint)) {
              /* console.log('User is subscribed to the correct endpoint.'); */
              setIsSubscribed(true);
            } else {
              /* console.log('User is subscribed to the wrong endpoint.'); */
              setIsSubscribed(false);
            }
          })
          .catch(function (error) {
            console.error('Failed to get the subscription:', error);
            setIsSubscribed(false);
          });
      })
      .catch(function (error) {
        console.error('Service Worker is not ready or failed to access:', error);
      });
  }

  const queryClient = useQueryClient();

  function unsubscribeUser() {
    navigator.serviceWorker.ready
      .then(function (registration) {
        registration.pushManager
          .getSubscription()
          .then(function (subscription) {
            if (!subscription) {
              console.log('No subscription found to unsubscribe.');
              setIsSubscribed(false);
              return;
            }
            // First, unsubscribe on the client side
            subscription
              .unsubscribe()
              .then(function () {
                console.log('User is unsubscribed.');
                // Then, inform the server about the unsubscription
                unsubscribe();
                // and invalidate the myNotifications query
                queryClient.invalidateQueries('myNotifications');
              })
              .catch(function (error) {
                console.error('Error unsubscribing on the client side:', error);
                unsubscribe();
              });
          })
          .catch(function (error) {
            console.error('Failed to get the subscription:', error);
          });
      })
      .catch(function (error) {
        console.error('Service Worker is not ready or failed to access:', error);
      });
  }

  function subscribeUserToPush(registration) {
    const camad = false;
    if (camad) return;
    const subscribeOptions = {
      userVisibleOnly: true,
      applicationServerKey: 'BDTpzabEJ_TNs6UQZrqlAP2lRbilugKJTMrYiOz-As2ItwP1beCCffdwCI76XNJm_I4vqhW_dSkYnzLD4jDh_q8'
    };
    console.log('Subscribing to push service...');

    registration.pushManager
      .subscribe(subscribeOptions)
      .then(async function (subscription) {
        const device_fingerprint = await getDeviceFingerprint();
        const payload = { ...subscription.toJSON(), device_fingerprint };
        subscribe(payload);
      })
      .catch(function (err) {
        console.error('Unable to subscribe to push', err);
      });
  }

  const handleSwitchPushNotifications = (event) => {
    console.log('Switch Push Notifications:', event.target.checked);

    if (event.target.checked) {
      askForPermission();
    } else {
      unsubscribeUser();
    }
  };

  useEffect(() => {
    checkNotificationPermission();
    checkSubscription();
  }, [userEndpoints]);

  const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? 'secondary.200' : 'secondary.200';
  const iconBackColor = theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'secondary.100';

  const badgeCountent = isSubscribed ? (unreadCount > 99 ? '99+' : unreadCount) : 'engellendi';
  return (
    <Tooltip title={'Bildirimlerim'}>
      <>
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
          <IconButton
            className="bildirimlerButonu"
            color="secondary"
            variant="light"
            onClick={handleToggle}
            aria-label="settings toggler"
            size="large"
            sx={{ color: 'secondary.main', bgcolor: open ? iconBackColorOpen : iconBackColor, p: 1 }}
          >
            <Badge badgeContent={badgeCountent} color="error" sx={{ '& .MuiBadge-badge': { top: 2, right: 4 } }}>
              <NotificationIcon variant="Bold" />
            </Badge>
          </IconButton>
        </Box>

        <Drawer
          sx={{ zIndex: 2001 }}
          anchor="right"
          onClose={handleToggle}
          open={open}
          PaperProps={{ sx: { width: { xs: 350, sm: 500 } } }}
        >
          {open && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  backgroundColor: 'background.default'
                }}
              >
                {/* Top Part - Sticky */}
                <Box
                  sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 2001,
                    backgroundColor: 'background.default'
                  }}
                >
                  <MainCard content={false} sx={{ border: 'none', borderRadius: 0, backgroundColor: 'background.default' }}>
                    <Box sx={{ p: 2.5, display: 'flex', flexDirection: 'column' }}>
                      <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Stack direction="row" alignItems="center" spacing={1.5}>
                          <Badge badgeContent={unreadCount} color="error" sx={{ '& .MuiBadge-badge': { top: 2, right: 4 } }}>
                            <NotificationIcon variant="Bold" />
                          </Badge>
                          <Typography variant="h4">Bildirimler</Typography>

                          <Button
                            onClick={() => {
                              readAllNotifications();
                            }}
                            variant="text"
                            color="primary"
                          >
                            Hepsini Okundu Yap
                          </Button>
                        </Stack>
                        <IconButton color="secondary" sx={{ p: 0 }} onClick={handleToggle}>
                          <Add size={28} style={{ transform: 'rotate(45deg)' }} />
                        </IconButton>
                      </Stack>
                    </Box>
                  </MainCard>
                </Box>

                {/* Scrollable Notification Cards */}
                <SimpleBar
                  sx={{
                    '& .simplebar-content': {
                      flexGrow: 1,
                      overflowY: 'auto',
                      backgroundColor: 'background.default'
                    },
                    p: 1.5
                  }}
                >
                  <Grid container spacing={1.5}>
                    {isLoading ||
                      (isFetching && (
                        <Grid item xs={12}>
                          <Stack direction="row" alignItems="center" justifyContent="center">
                            <CircularProgress color="secondary" size={32} thickness={4} />
                          </Stack>
                        </Grid>
                      ))}
                    {myNotifications.map((notification) => (
                      <Grid key={notification.id} item xs={12}>
                        {isLoading ? <NotificationCardSkeleton /> : <NotificationCard notification={notification} />}
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <Stack direction="row" alignItems="center" spacing={1.5} sx={{ mt: 2 }}>
                        <Button
                          onClick={() => {
                            navigate('/notifications');
                            handleToggle();
                          }}
                          variant="text"
                          color="primary"
                        >
                          Tümünü Gör
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </SimpleBar>

                {/* Bottom Part - Sticky */}
                <Box
                  sx={{
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 2001,
                    backgroundColor: 'background.default'
                  }}
                >
                  <MainCard content={false} sx={{ border: 'none', borderRadius: 0, backgroundColor: 'background.default' }}>
                    <Box sx={{ p: 2 }}>
                      <Stack direction="row" alignItems="center" justifyContent={'space-between'}>
                        <Stack direction="row" alignItems="center" spacing={1.5}>
                          <Setting2 variant="Bold" />
                          <Typography variant="h4">Ayarlar</Typography>
                        </Stack>
                        <FormControlLabel
                          control={<Switch checked={isSubscribed} onChange={handleSwitchPushNotifications} />}
                          labelPlacement="start"
                          label="Bildirimleri Aç/Kapat"
                        />
                      </Stack>
                    </Box>
                  </MainCard>
                </Box>
              </Box>
            </>
          )}
        </Drawer>
      </>
    </Tooltip>
  );
};

export default MyNotifications;
