// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  DocumentCode2,
  Profile2User,
  UserEdit,
  UserAdd,
  UserTag,
  OceanProtocol,
  Level,
  ShieldCross,
  InfoCircle,
  I24Support,
  Driving,
  BookSaved,
  PresentionChart,
  Data2,
  /*  Wallet,
  Wallet3,
  WalletAdd, */
  Note,
  NoteAdd,
  Notepad2,
  Box,
  Box1,
  BoxAdd,
  Home3,
  Home2,
  House2,
  Briefcase,
  BoxTime,
  TruckFast,
  TruckTick,
  Box2
} from 'iconsax-react';

// icons
const icons = {
  samplePage: DocumentCode2,
  buyRequest: Note,
  buyRequests: Notepad2,
  offers: Briefcase,
  orders: TruckFast,
  intakes: TruckTick,
  createBuyRequest: NoteAdd,
  product: Box,
  allProducts: Box1,
  requestedProducts: BoxTime,
  addProduct: BoxAdd,
  storage: House2,
  allStorages: Home2,
  addStorage: Home3,
  logs: BookSaved,
  chart: PresentionChart,
  list: Data2,
  allUsers: Profile2User,
  editUsers: UserEdit,
  createUsers: UserAdd,
  userDetail: UserTag,
  menuLevel: OceanProtocol,
  menuLevelSubtitle: Level,
  disabledMenu: ShieldCross,
  chipMenu: InfoCircle,
  documentation: I24Support,
  roadmap: Driving,
  quotations: Box2
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const stockManagement = {
  id: 'buying-management',
  title: <FormattedMessage id="buy-management" />,
  type: 'group',
  children: [
    {
      id: 'product-list',
      title: <FormattedMessage id="product-list" />,
      type: 'item',
      url: '/all-products',
      icon: icons.product
    },
    {
      id: 'quotations',
      title: <FormattedMessage id="quotations" />,
      type: 'item',
      url: '/quotations',
      icon: icons.quotations,
      chip: {
        color: 'error',
        label: 'Yeni'
      }
    },
    {
      id: 'create-buy-request',
      title: <FormattedMessage id="create-request" />,
      type: 'item',
      url: '/create-buy-request',
      icon: icons.createBuyRequest
    },
    {
      id: 'buy-requests',
      title: <FormattedMessage id="buy-requests" />,
      type: 'item',
      url: '/buy-requests',
      icon: icons.buyRequest
    },
    {
      id: 'requested-product-list',
      title: <FormattedMessage id="requested-product-list" />,
      type: 'item',
      url: '/requested-products',
      icon: icons.requestedProducts
    },
    {
      id: 'offers',
      title: <FormattedMessage id="offers" />,
      type: 'item',
      url: '/offers',
      icon: icons.offers
      /* disabled: true */
    },
    {
      id: 'orders',
      title: <FormattedMessage id="orders" />,
      type: 'item',
      url: '/orders',
      icon: icons.orders
      /* disabled: true */
    },
    {
      id: 'intakes',
      title: <FormattedMessage id="intakes" />,
      type: 'item',
      url: '/intakes',
      icon: icons.intakes
      /* chip: {
        color: 'primary',
        label: 'Yeni'
      } */
      /* disabled: true */
    },

    {
      id: 'storage',
      title: <FormattedMessage id="stock-management" />,
      type: 'collapse',
      icon: icons.storage,
      disabled: true,
      children: [
        {
          id: 'storages',
          title: <FormattedMessage id="storages" />,
          type: 'item',
          url: '/storages',
          icon: icons.allStorages
          /* disabled: true */
        },
        {
          id: 'stocks',
          title: <FormattedMessage id="stocks" />,
          type: 'item',
          url: '/stocks',
          icon: icons.storage
        }
      ]
    }
  ]
};

export default stockManagement;
