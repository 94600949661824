import PropTypes from 'prop-types';

// material-ui
/* import { useTheme } from '@mui/material/styles';
 */
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */
/* import logo from 'assets/images/firatlogo.png'; */

// ==============================|| LOGO SVG ||============================== //

const LogoMain = () => {
  /* const theme = useTheme(); */
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === ThemeMode.DARK ? logoDark : logo} alt="icon logo" width="100" />
     *
     */
    <img src={'/firatlogo.png'} alt="icon logo" width="100" />
  );
};

LogoMain.propTypes = {
  reverse: PropTypes.bool
};

export default LogoMain;
