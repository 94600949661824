import React, { useState, useEffect, useCallback } from 'react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import useConfig from 'hooks/useConfig';
import debounce from 'lodash.debounce';
import { parseISO, isValid } from 'date-fns';
import { tr, enUS } from 'date-fns/locale';

const localesMap = {
  tr: tr,
  en: enUS
};

const DateSelect = ({ value, onChange, label, ...props }) => {
  const { i18n } = useConfig();
  const [localValue, setLocalValue] = useState(typeof value === 'string' ? parseISO(value) : value);

  const format = i18n === 'tr' ? 'dd.MM.yyyy' : 'MM/dd/yyyy';
  const timezone = i18n === 'tr' ? /* 'UTC+3' */ 'UTC' : 'UTC';

  const handleDateChange = (newValue) => {
    setLocalValue(newValue);
    debounceOnChange(newValue);
  };

  const debounceOnChange = useCallback(
    debounce((newDate) => {
      if (newDate && isValid(newDate)) {
        onChange(newDate);
      } else if (newDate === null) {
        onChange(null);
      }
    }, 1000),
    [onChange]
  );

  useEffect(() => {
    if (typeof value === 'string') {
      setLocalValue(parseISO(value));
    } else {
      setLocalValue(value);
    }
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localesMap[i18n]}>
      <DatePicker
        {...props}
        format={format}
        timezone={timezone}
        label={label}
        value={localValue}
        onChange={handleDateChange}
        localeText={{
          cancelButtonLabel: i18n === 'tr' ? 'İptal' : 'Cancel',
          okButtonLabel: i18n === 'tr' ? 'Tamam' : 'Ok'
        }}
        slotProps={{
          textField: {
            variant: 'outlined'
          }
        }}
      />
    </LocalizationProvider>
  );
};

export default DateSelect;
