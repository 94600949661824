import { useRoutes, Navigate } from 'react-router-dom';

// project-imports
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import SpecialRoutes from './SpecialRoutes';

// ==============================|| ROUTES RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([LoginRoutes, SpecialRoutes, MainRoutes, { path: '*', element: <Navigate to="/404" /> }]);
}

export const AllRoutes = [LoginRoutes, SpecialRoutes, MainRoutes, { path: '*', element: <Navigate to="/404" /> }];
