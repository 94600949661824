import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import ReactApexChart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';
import { ThemeMode } from 'config';

const OfferedProductAmountsChart = ({ chart }) => {
  // Add requestedAmount as a prop
  const { series, offer_ids, supplier_names, requested_amount = 0 } = chart;
  const theme = useTheme();
  const navigate = useNavigate();

  const mode = theme.palette.mode;
  const line = theme.palette.divider;
  const { primary } = theme.palette.text;

  const handleOfferClick = (offer_id) => {
    navigate(`/offers/${offer_id}`);
  };

  // Determine the maximum value in the series
  const maxSeriesValue = Math.max(...series.map((s) => Math.max(...s.data)));
  const xaxisMax = Math.max(maxSeriesValue, requested_amount);

  const barChartOptions = {
    chart: {
      type: 'bar',
      height: 300,
      events: {
        click: function (event, chartContext, config) {
          if (config.dataPointIndex !== -1) {
            handleOfferClick(offer_ids[config.dataPointIndex]);
          }
        },
        legendClick: function (chartContext, seriesIndex, config) {
          // Hide all series
          config.globals.seriesNames.forEach((seriesName, index) => {
            if (index !== seriesIndex) {
              chartContext.hideSeries(seriesName);
            }
          });

          // Show the clicked series
          chartContext.showSeries(config.globals.seriesNames[seriesIndex]);
        }
      },
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: true,
        startingShape: 'flat',
        endingShape: 'flat',
        distributed: false // This needs to be true to apply different colors per data point
      }
    },
    colors: [theme.palette.success.main],
    annotations: {
      xaxis: [
        {
          x: requested_amount, // Position the line at the requested amount
          borderColor: theme.palette.error.main, // Color of the line
          borderRadius: 5,
          label: {
            borderColor: theme.palette.error.main,
            style: {
              color: '#fff',
              fontSize: '14px',
              background: theme.palette.error.main
            },
            text: `Talep: ${requested_amount}` // Label text
          }
        }
      ]
    },
    tooltip: {
      y: {
        title: {
          formatter: function () {
            return '';
          }
        }
      }
    },
    theme: {
      mode: mode === ThemeMode.DARK ? 'dark' : 'light'
    },
    legend: {
      onItemClick: {
        toggleDataSeries: false
      }
    }
  };

  const [options, setOptions] = useState(barChartOptions);

  const categories = offer_ids.map((offer_id, index) => `${supplier_names[index]} | Teklif #${offer_id}`);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      xaxis: {
        ...prevState.xaxis,
        max: xaxisMax,
        categories: categories
      },
      grid: {
        borderColor: line
      },
      theme: {
        mode: mode === ThemeMode.DARK ? 'dark' : 'light'
      },
      chart: {
        ...prevState.chart,
        background: undefined
      }
    }));

    setTimeout(() => {
      // resize the chart
      window.dispatchEvent(new Event('resize'));
    }, 10);
  }, [mode, primary, line, theme.palette, chart]);

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="bar" height={series?.[0]?.data?.length * 40 + 200} />
    </div>
  );
};

export default OfferedProductAmountsChart;
