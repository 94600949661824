import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:8800';
console.log('baseURL', baseURL);

const myAxios = axios.create({ baseURL, withCredentials: true });

myAxios.interceptors.response.use(
  (response) => {
    const data = response.data;

    if (data.m) {
      enqueueSnackbar(response.data.m, { variant: 'success', preventDuplicate: true, autoHideDuration: 2000 });
    }

    return response;
  },
  (error) => {
    const response = error.response;
    if (!response) {
      enqueueSnackbar('Sunucuya ulaşılamıyor, lütfen daha sonra tekrar deneyin', {
        variant: 'warning',
        preventDuplicate: true,
        autoHideDuration: 2000
      });
      return Promise.reject(error);
    }

    const data = response.data;
    console.log('status', response.status);
    console.log('data', data);

    if (data?.m) {
      enqueueSnackbar(data.m, { variant: 'error', preventDuplicate: true, autoHideDuration: 2000 });
    }

    if (response.status === 401) {
      if (data.redirect) {
        setTimeout(() => {
          window.location.pathname = '/login';
        }, 400);
      }
    } else if (response.status === 429) {
      // TOO MANY REQUESTS
      enqueueSnackbar(data?.m || 'Çok fazla istek gönderdiniz. Lütfen daha sonra tekrar deneyiniz.', {
        variant: 'error',
        preventDuplicate: true,
        autoHideDuration: 2000
      });
    }

    return Promise.reject((response && response.data) || 'Beklenmeyen hata oluştu.');
  }
);

export default myAxios;
