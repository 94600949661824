import { useMemo } from 'react';

// material-ui
import { Box, useMediaQuery } from '@mui/material';

// project-imports
/* import Search from './Search'; */
import MainProject from './MainProject';
import MyNotifications from './MyNotifications';
import FullScreen from './FullScreen';
import Profile from './Profile';
import Localization from './Localization';
/* import MobileSection from './MobileSection'; */
/* import MegaMenuSection from './MegaMenuSection'; */

import useConfig from 'hooks/useConfig';
import DrawerHeader from 'layout/MainLayout/Drawer/DrawerHeader';
import { MenuOrientation } from 'config';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { i18n, menuOrientation } = useConfig();

  const downLG = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const localization = useMemo(() => <Localization />, [i18n]);

  /* const megaMenu = useMemo(() => <MegaMenuSection />, []); */

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && <DrawerHeader open={true} />}
      {/*  {!downLG && <Search />} */}
      {/* !downLG &&  */ <MainProject />}
      <Box sx={{ flexGrow: 1 }} />
      {/*  {!downLG && megaMenu} */}
      {/* !downLG &&  */ localization}
      {/* {downLG && <Box sx={{ width: '100%', ml: 1 }} />} */}
      {!downLG && <FullScreen />}
      <MyNotifications />
      {/* !downLG &&  */ <Profile />}

      {/* {downLG && <MobileSection />} */}
    </>
  );
};

export default HeaderContent;
