import PropTypes from 'prop-types';
import { useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// material-ui
import { Button, Box, Dialog, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import { /* HidingSelect, */ HeaderSort, ExportOptions, EmptyTable } from 'components/third-party/ReactTable';
import { SearchFilter, DefaultColumnFilter } from 'utils/react-table';
import IconButton from 'components/@extended/IconButton';
import MovementPanel from 'components/MovementPanel';
import { PopupTransition } from 'components/@extended/Transitions';
import FilterDrawer from '../filtering/FilterDrawer';
import TimestampCell from 'components/data/TimestampCell';
import CompanyCell from 'components/data/CompanyCell';
import QuotationCreationForm from 'sections/forms/validation/QuotationCreationForm';

// assets
import { ArrowDown2, ArrowRight2, FilterSearch, Edit, Discover, Box2 } from 'iconsax-react';

// third-party
import { FormattedMessage } from 'react-intl';
import { useExpanded, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { FormattedNumber } from 'react-intl';

// ==============================|| REACT TABLE - UMBRELLA ||============================== //

const QuotationTable = ({ company, data, search, setSearch, setSortBy, Pagination, filters, setFilters }) => {
  const location = useLocation();
  const isCompanyTab = location.pathname.includes('companies');
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        disableFilters: true,
        disableGroupBy: true
      },

      {
        Header: 'Tedarikçi Firma',
        accessor: 'supplier',
        disableFilters: true,
        disableGroupBy: true,
        disableSortBy: true,
        Cell: ({ value }) => <CompanyCell maxWidth={300} company={value} />
      },
      {
        Header: 'Talep Eden Firma',
        accessor: 'demanding',
        disableFilters: true,
        disableGroupBy: true,
        disableSortBy: true,
        Cell: ({ value }) => <CompanyCell maxWidth={300} company={value} />
      },
      {
        Header: 'Net Ödenecek Tutar',
        accessor: 'net_total',
        className: 'cell-right',
        disableFilters: true,
        disableGroupBy: true,
        Cell: ({ value }) => (
          <FormattedNumber value={value} style="currency" currency="TRY" minimumFractionDigits={2} maximumFractionDigits={2} />
        )
      },
      {
        Header: 'Ürün Sayısı',
        accessor: 'product_count',
        className: 'cell-right',
        disableFilters: true,
        disableGroupBy: true
      },
      {
        Header: 'Açıklama',
        accessor: 'description',
        className: 'cell-left',
        disableFilters: true,
        disableGroupBy: true,
        disableSortBy: true
      },

      {
        Header: 'Oluşturma',
        accessor: 'created_at',
        disableFilters: true,
        disableGroupBy: true,
        Cell: ({ value }) => <TimestampCell value={value} />
      },

      {
        Header: '',
        accessor: 'action1',
        disableFilters: true,
        disableGroupBy: true,
        disableSortBy: true,
        Cell: ({ row }) => (
          <Stack direction="row" alignItems="center" spacing={0} sx={{ maxHeight: 0 }}>
            <IconButton
              onClick={() => {
                setObject(row.original);
                setItemToUpdate(row.original);
                setCreationVisible(true);
              }}
              color="secondary"
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={() => {
                setObject(row.original);
                handleDrawerOpen();
              }}
              color="secondary"
            >
              <Discover variant="Bulk" />
            </IconButton>
          </Stack>
        )
      }
    ],
    []
  );

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter
    }),
    []
  );

  const initialState = useMemo(
    () => ({
      hiddenColumns: [],
      sortBy: [{ id: 'created_at', desc: true }]
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    allColumns,
    state: { hiddenColumns, sortBy }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState,
      manualSortBy: true,
      manualPagination: true // This is important for server-side pagination
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  let headers = [];
  allColumns.map((item) => {
    if (!hiddenColumns?.includes(item.id) && item.id !== 'selection' && item.id !== 'edit') {
      headers.push({ label: typeof item.Header === 'string' ? item.Header : '#', key: item.id });
    }
    return item;
  });

  useEffect(() => {
    setSortBy(sortBy[0]);
  }, [sortBy]);

  const navigate = useNavigate();
  /* const [visible, setVisible] = useState(false); */
  const [object, setObject] = useState(null);
  const [panelVisible, setPanelVisible] = useState(false);
  const [creationVisible, setCreationVisible] = useState(false);
  const [itemToUpdate, setItemToUpdate] = useState(null);
  console.log('itemToUpdate', itemToUpdate);
  useEffect(() => {
    if (!creationVisible) {
      setItemToUpdate(null);
    }
  }, [creationVisible]);

  const handleDrawerOpen = () => {
    setPanelVisible(!panelVisible);
  };

  const handleFilterDrawerOpen = () => {
    console.log('filters', filters);
    setFilterDrawerVisible(!filterDrawerVisible);
  };

  const [filterDrawerVisible, setFilterDrawerVisible] = useState(false);

  return (
    <MainCard content={false}>
      <FilterDrawer
        table_name={'quotations'}
        filters={filters}
        setFilters={setFilters}
        handleDrawerOpen={handleFilterDrawerOpen}
        openFilterDrawer={filterDrawerVisible}
      />
      <Dialog
        maxWidth="xl"
        fullWidth
        TransitionComponent={PopupTransition}
        onClose={() => {
          setCreationVisible(false);
        }}
        scroll="body"
        open={creationVisible}
        sx={{ '& .MuiDialog-paper': { p: 0 /* bgcolor: 'transparent' */ } }}
      >
        <QuotationCreationForm company={company} itemToUpdate={itemToUpdate} setCreationVisible={setCreationVisible} />
      </Dialog>
      <MovementPanel open={panelVisible} handleDrawerOpen={handleDrawerOpen} table_name={'quotations'} object_id={object?.id} />
      <ScrollX>
        <Stack padding={2}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={1.5}>
              <Button onClick={handleFilterDrawerOpen} variant="outlined" endIcon={<FilterSearch variant="TwoTone" />} fullWidth>
                {filters && Object.keys(filters).length > 0 ? `Filtreler (${Object.keys(filters).length})` : 'Filtrele'}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={1.5}>
              <SearchFilter globalFilter={search} setGlobalFilter={setSearch} size="small" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={1.5}>
              <IconButton
                onClick={() => {
                  setCreationVisible(true);
                }}
                sx={{ width: 'fit-content', columnGap: 1, alignItems: 'center' }}
                color="primary"
                variant="contained"
              >
                {<Box2 />}
                <FormattedMessage id="add-company-quotation" />
              </IconButton>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={1.5}>
              <ExportOptions
                data={data}
                filename={`Bağlantılar - ${new Date().toISOString()}`}
                columns={columns}
                hiddenColumns={hiddenColumns}
              />
            </Grid>
          </Grid>
        </Stack>

        <Box sx={{ width: '100%', overflowX: 'auto', display: 'block' }}>
          <Table size="small" {...getTableProps()}>
            <TableHead sx={{ borderTopWidth: 2 }}>
              {headerGroups.map((headerGroup) => (
                <TableRow key={headerGroup} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell key={column} {...column.getHeaderProps([{ className: column.className }])}>
                      <HeaderSort column={column} sort />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>

            {/* striped table -> add class 'striped' */}
            <TableBody {...getTableBodyProps()} /* className="striped" */>
              {headerGroups.map((group) => (
                <TableRow key={group} {...group.getHeaderGroupProps()}>
                  {/* {group.headers.map((column) => (
                    <TableCell key={column} {...column.getHeaderProps([{ className: column.className }])}>
                      {column.canFilter ? column.render('Filter') : null}
                    </TableCell>
                  ))} */}
                </TableRow>
              ))}
              {page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      key={row}
                      {...row.getRowProps()}
                      {...{
                        onClick: () => {
                          if (isCompanyTab) {
                            navigate(`/companies/${company?.id}/quotations/${row.original.id}`);
                          } else {
                            navigate(`/quotations/${row.original.id}`);
                          }
                        }
                      }}
                      sx={{ cursor: 'pointer', bgcolor: 'inherit' }}
                    >
                      {row.cells.map((cell) => {
                        let bgcolor = 'inherit';
                        if (cell.isGrouped) bgcolor = 'success.lighter';
                        if (cell.isAggregated) bgcolor = 'warning.lighter';
                        if (cell.isPlaceholder) bgcolor = 'error.lighter';
                        if (cell.isPlaceholder) bgcolor = 'error.lighter';
                        const collapseIcon = row.isExpanded ? <ArrowDown2 /> : <ArrowRight2 />;

                        return (
                          <TableCell key={cell} {...cell.getCellProps([{ className: cell.column.className }])} sx={{ bgcolor }}>
                            {cell.isGrouped ? (
                              <Stack direction="row" spacing={1} alignItems="center" sx={{ display: 'inline-flex' }}>
                                <Box
                                  sx={{ pr: 1.25, fontSize: '0.75rem', color: 'text.secondary' }}
                                  onClick={(e) => {
                                    row.toggleRowExpanded();
                                    e.stopPropagation();
                                  }}
                                >
                                  {collapseIcon}
                                </Box>
                                {cell.render('Cell')} ({row.subRows.length})
                              </Stack>
                            ) : cell.isAggregated ? (
                              cell.render('Aggregated')
                            ) : cell.isPlaceholder ? null : (
                              cell.render('Cell')
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <EmptyTable msg="Eleman Yok" colSpan={columns.length} />
              )}
              <TableRow>
                <TableCell sx={{ p: 2 }} colSpan={columns.length}>
                  {Pagination && <Pagination />}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </ScrollX>
    </MainCard>
  );
};

QuotationTable.propTypes = {
  row: PropTypes.object,
  setEditableRowIndex: PropTypes.func,
  editableRowIndex: PropTypes.string,
  index: PropTypes.string,
  getRowProps: PropTypes.func,
  toggleRowSelected: PropTypes.func,
  isSelected: PropTypes.bool,
  isExpanded: PropTypes.bool,
  toggleRowExpanded: PropTypes.func,
  subRows: PropTypes.object,
  length: PropTypes.string,
  getToggleAllPageRowsSelectedProps: PropTypes.func,
  getToggleRowSelectedProps: PropTypes.func,
  value: PropTypes.string
};

export default QuotationTable;
