import { useQuery } from 'react-query';
import axios from 'utils/axios2';

const fetchMovementPanelData = ({ queryKey }) => {
  const table_name = queryKey[1];
  const object_id = queryKey[2];
  return axios.get(`/api/movement_panel?table_name=${table_name}&object_id=${object_id}`);
};

export const useMovementPanelData = (table_name, object_id, enabled) => {
  const isEnabled = enabled && table_name !== undefined && object_id !== undefined;
  return useQuery(['movement_panel', table_name, object_id], fetchMovementPanelData, {
    enabled: isEnabled,
    cacheTime: 0.2 * 60 * 1000,
    staleTime: 0.2 * 60 * 1000
  });
};
